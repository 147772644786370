@import "config";
@import "utilities";
@import "typography";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Poppins", sans-serif;
  // font-size: 1.125rem;
  line-height: 1.6;
  overflow-x: hidden;
}

div#root {
  background-color: var(--ui-background);
  color: var(--text-default);
  min-height: 100vh;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

.mapboxgl-control-container {
  display: none;
}